<template>
  <v-flex xs12 sm6 mt-5 :key="dutConfig.index">
    <v-card outlined width="400">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>DUT {{ dutConfig.name }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div class="body-2 text--primary text-truncate" v-on="on">
              Filename: <span class="text--warning">{{ fileName }}</span>
            </div>
          </template>
          <span>{{ fileName }}</span>
        </v-tooltip>
        <v-row dense no-gutters class="mt-5">
          <v-col cols="12">
            <base-badge-counter
              v-if="tpCount"
              :itemCount="tpCount"
              :useIcon="true"
              :leftMargin="0"
              viewIcon="mdi-clipboard-list"
              tooltipText="Total number of Test Points"
            />
            <base-badge-counter
              v-if="p125Count"
              :itemCount="p125Count"
              :useIcon="false"
              :leftMargin="5"
              color="primary"
              viewIcon="R125"
              tooltipText="Receptacles - 125 mil pitch"
            />
            <base-badge-counter
              v-if="p100Count"
              :itemCount="p100Count"
              :useIcon="false"
              :leftMargin="5"
              color="primary"
              viewIcon="R100"
              tooltipText="Receptacles - 100 mil pitch"
            />
            <base-badge-counter
              v-if="p75Count"
              :itemCount="p75Count"
              :useIcon="false"
              :leftMargin="5"
              color="primary"
              viewIcon="R75"
              tooltipText="Receptacles - 75 mil pitch"
            />
            <base-badge-counter
              v-if="p50Count"
              :itemCount="p50Count"
              :useIcon="false"
              :leftMargin="5"
              color="primary"
              viewIcon="R50"
              tooltipText="Receptacles - 50 mil pitch"
            />
            <base-badge-counter
              v-if="pEmptyCount"
              :itemCount="pEmptyCount"
              :useIcon="false"
              :leftMargin="5"
              color="primary"
              viewIcon="NP"
              tooltipText="Not Populated. FixturFab detected these Test Points, but no Receptacles or Probes have been selected. Empty holes will be drilled."
            />
            <base-badge-counter
              v-if="gpCount"
              :itemCount="gpCount"
              :useIcon="true"
              :leftMargin="5"
              viewIcon="mdi-clipboard-arrow-up"
              tooltipText="Total number of Guide Pins"
            />
            <base-badge-counter
              v-if="ppCount"
              :itemCount="ppCount"
              :useIcon="true"
              :leftMargin="5"
              viewIcon="mdi-clipboard-arrow-down"
              tooltipText="Total number of Pressure Pins"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                text
                color="primary"
                :disabled="!isAdmin"
                @click="
                  gotoRoute({
                    route: 'configure',
                    id: dutConfig.project,
                    did: dutConfig.pk,
                    newTab: false,
                  })
                "
                @contextmenu="
                  activateMenu({
                    nextRoute: {
                      route: 'configure',
                      id: dutConfig.project,
                      did: dutConfig.pk,
                      newTab: true,
                    },
                    nextAction: 'gotoRoute',
                    event: $event,
                  })
                "
                ><v-icon>mdi-cog</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Test Points, Guide Pins, and Pressure Pins Configuration</span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                text
                color="warning"
                :disabled="!isAdmin"
                @click="
                  gotoRoute({
                    route: 'render',
                    id: dutConfig.project,
                    did: dutConfig.pk,
                    newTab: false,
                  })
                "
                @contextmenu="
                  activateMenu({
                    nextRoute: {
                      route: 'render',
                      id: dutConfig.project,
                      did: dutConfig.pk,
                      newTab: true,
                    },
                    nextAction: 'gotoRoute',
                    event: $event,
                  })
                "
                ><v-icon>mdi-rotate-3d</v-icon>
              </v-btn>
            </span>
          </template>
          <span>3D Render Configuration</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      @onClick="subMenuClickHandler"
      @onCopyUrl="subMenuCopyUrl"
    />
  </v-flex>
</template>
<script>
import SubMenuPopup from "@/mixins/SubMenuPopup";
export default {
  name: "DutConfigItem",
  mixins: [SubMenuPopup],
  props: {
    dutConfig: {
      type: Object,
      default: null,
    },
    fixtureState: {
      type: Number,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      receptacle50: 1.27,
      receptacle75: 1.91,
      receptacle100: 2.54,
      receptacle125: 3.175,
    };
  },
  computed: {
    fileName() {
      return this.dutConfig.testpoint_file?.split("?")[0].split("/").pop();
    },
    tpCount() {
      return this.dutConfig.testpoints ? this.dutConfig.testpoints.length : 0;
    },
    gpCount() {
      return this.dutConfig.guide_pins ? this.dutConfig.guide_pins.length : 0;
    },
    ppCount() {
      return this.dutConfig.pressure_pins
        ? this.dutConfig.pressure_pins.length
        : 0;
    },
    p125Count() {
      return this.dutConfig.testpoints.filter(
        (element) =>
          element.receptacle?.pitch &&
          element.receptacle?.pitch == this.receptacle125
      ).length;
    },
    p100Count() {
      return this.dutConfig.testpoints.filter(
        (element) =>
          element.receptacle?.pitch &&
          element.receptacle?.pitch == this.receptacle100
      ).length;
    },
    p75Count() {
      return this.dutConfig.testpoints.filter(
        (element) =>
          element.receptacle?.pitch &&
          element.receptacle?.pitch == this.receptacle75
      ).length;
    },
    p50Count() {
      return this.dutConfig.testpoints.filter(
        (element) =>
          element.receptacle?.pitch &&
          element.receptacle?.pitch == this.receptacle50
      ).length;
    },
    pEmptyCount() {
      return (
        this.tpCount -
        (this.p125Count + this.p100Count + this.p75Count + this.p50Count)
      );
    },
  },
  methods: {
    gotoRoute({ route, id, did, newTab = false }) {
      if (newTab) {
        let routeData = this.$router.resolve({
          name: route,
          params: { id, did },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: route,
          params: { id, did },
        });
      }
    },
    async gotoRouteCopyUrl({ route, id, did }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: route,
          params: { id, did },
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
  },
};
</script>
